import styled from 'styled-components';

const PhotoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45vh; /* Default height */
  background-color: ; /* Set the background color to black */

  /* Responsive adjustments */
  @media (max-width: 768px) {
    height: 50vh; /* Adjust height for tablets */
  }

  @media (max-width: 480px) {
    height: 45vh; /* Adjust height for mobile devices */
  }
`;

const Photo = styled.img`
  margin-top: 30px;
  height: 350px;
  width: auto;
`;

export default function CenteredPhoto({ src, alt }) {
  return (
    <PhotoWrapper>
      <Photo src={src} alt={alt} />
    </PhotoWrapper>
  );
}
